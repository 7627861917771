// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-about-template-js": () => import("./../../../src/templates/aboutTemplate.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-blog-listing-template-js": () => import("./../../../src/templates/blogListingTemplate.js" /* webpackChunkName: "component---src-templates-blog-listing-template-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/contactTemplate.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-home-blog-template-js": () => import("./../../../src/templates/homeBlogTemplate.js" /* webpackChunkName: "component---src-templates-home-blog-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/homeTemplate.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-not-found-template-js": () => import("./../../../src/templates/NotFoundTemplate.js" /* webpackChunkName: "component---src-templates-not-found-template-js" */),
  "component---src-templates-team-template-js": () => import("./../../../src/templates/teamTemplate.js" /* webpackChunkName: "component---src-templates-team-template-js" */),
  "component---src-templates-term-template-js": () => import("./../../../src/templates/termTemplate.js" /* webpackChunkName: "component---src-templates-term-template-js" */)
}

