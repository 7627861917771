import { Link } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import classNames from 'classnames'
import { searchFilter } from '../../lib/utils'

export default function SearchBox({ className, postLinks }) {
    console.log(`SearchBox:postLinks`, postLinks)

    const [items, setItems] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const [visible, setVisible] = useState(false)
    const dropdownRef = useRef(null)

    useEffect(() => {
        setItems(postLinks)
    }, [postLinks])

    useEffect(() => {
        if (searchValue && searchValue !== '') {
            const filteredList = searchFilter(searchValue, postLinks)
            setItems(filteredList)
        }
    }, [searchValue, postLinks])

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);
        return () => document.removeEventListener('mousedown', handleClick, false);
    }, []);


    const handleClick = e => {
        if (dropdownRef.current.contains(e.target)) {
            return;
        }
        setVisible(false);
    };

    const handleFocus = () => {
        // if (searchValue) {
        setVisible(true);
        // };
    }

    const handleChange = e => {
        setSearchValue(e.target.value);
        if (!visible) {
            setVisible(true);
        }
    };

    const handleClearClick = () => { }

    const selectItem = (item) => {
        setVisible(false);
    };


    return (
        <div className={className}>
            <div className="search__box">
                <button className="search__box__btn "><FaSearch /></button>
                <input type="text" className="search__box__input" placeholder="Type to Search..." value={searchValue} onChange={handleChange} onKeyUp={handleChange} onFocus={handleFocus} />
            </div>
            <div ref={dropdownRef} className={classNames("search__dropdown", { "d-none": !visible })}>
                {items && items.length === 0 && (
                    <div className="search__dropdown__item">
                        <p>No publications found!</p>
                    </div>
                )}
                {items &&
                    items.map(obj => (
                        <div
                            key={obj.slug}
                            className="search__dropdown__item"
                        >
                            <a target="_blank" href={obj.path} onClick={() => selectItem(obj)}>{obj.title}</a>
                        </div>
                    ))}
            </div>
        </div>
    )
}
