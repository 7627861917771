import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Badges from "../common/Badges"
import SocialLinks from "../common/SocialLinks"
import { BLOCKS } from "@contentful/rich-text-types"
import FooterLink from "./FooterLink"

export default function Footer(props) {
  const { widget, latestPostLinks } = props
  // parse footer link richText
  const nodes = JSON.parse(widget.node.text.raw).content
  console.log(`Footer:widget`, widget)
  console.log(`Footer:latestPostLinks`, latestPostLinks)
  console.log(`Footer:nodes`, nodes)

  let linkCols = [],
    columnIndex = -1,
    countCols

  for (let i = 0; i < nodes.length; i++) {
    let node = nodes[i]
    if (node.nodeType === BLOCKS.HEADING_5) {
      columnIndex++
      linkCols[columnIndex] = { name: node.content[0].value, items: [] }
    }
    if (node.nodeType === BLOCKS.PARAGRAPH) {
      linkCols[columnIndex].items.push(node)
    }
  }
  countCols = columnIndex + 1

  return (
    <footer>
      <Container>
        <Row>
          <Col lg={4} sm={12}>
            <SocialLinks />
            <h5 className="copyright">
              Alcoholism.org. ©
              <br />
              1-888-767-3708
              <br />
              Copyright 2021 Addiction Solutions, LLC <br />
              All Rights Reserved
            </h5>
            {/* {widget.node.childWidgets && (
              <Badges nodes={widget.node.childWidgets} />
            )} */}
          </Col>
          <Col lg={8} sm={12}>
            <Row>
              {linkCols.map(linkBlock => {
                return (
                  <Col key={linkBlock.name} lg={12 / (countCols + 1)} sm={6}>
                    <h5>{linkBlock.name}</h5>
                    {linkBlock.items.map((obj, index) => (
                      <FooterLink key={index} node={obj} />
                    ))}
                  </Col>
                )
              })}
              <Col lg={12 / (countCols + 1)} sm={6}>
                <h5>Recent Publications</h5>
                {latestPostLinks.map((obj, index) => (
                  <a key={index} href={obj.path ? obj.path.toLowerCase() : "/"}>
                    {obj.title}
                  </a>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
