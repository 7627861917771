import React from "react"
import CrunchbaseIcon from "../../assets/Icons/png/c.png"

import {
  FaMediumM,
  FaLinkedinIn,
  FaTwitter,
  FaReddit,
  FaFacebookF,
  FaInstagram,
} from "react-icons/fa"

const links = [
  {
    label: "medium",
    href: "https://alcoholismorg.medium.com",
    image: FaMediumM,
  },
  {
    label: "linkedin",
    href: "https://www.linkedin.com/company/alcoholism-org",
    image: FaLinkedinIn,
  },
  {
    label: "twitter",
    href: "https://twitter.com/AlcoholismOrg",
    image: FaTwitter,
  },
  {
    label: "reddit",
    href: "https://www.reddit.com/r/alcoholismorg",
    image: FaReddit,
  },
  {
    label: "facebook",
    href: "https://www.facebook.com/Alcoholismorg-1035850421320071",
    image: FaFacebookF,
  },
  {
    label: "instagram",
    href: "https://www.instagram.com/alcoholism_org/",
    image: FaInstagram,
  },
  // {
  //   label: "crunchbase",
  //   href: "https: //www.crunchbase.com/organization/alcoholism-org",
  //   image: FaMediumM,
  // },
]

export default function SocialLinks() {
  return (
    <div className="social-links">
      {links.map((link, index) => (
        <div key={index} className="social-links__item">
          <a target="_blank" href={link.href} rel="nofollow">
            {<link.image />}
          </a>
        </div>
      ))}
      <div className="social-links__item">
        <a
          target="_blank"
          rel="nofollow"
          href="https://www.crunchbase.com/organization/alcoholism-org"
        >
          <img src={CrunchbaseIcon} alt="cruchbase" />
        </a>
      </div>
    </div>
  )
}
