import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "../styles/style.scss"
import Footer from "./layouts/Footer"
import TopNavbar from "./layouts/TopNavbar"
import Notification from "./common/Notification"
import ScrollToTop from "./common/ScrollToTop"
import useIsScrolled from "../lib/useIsScrolled"

export default function Layout(props) {
  let isScrolled = useIsScrolled()
  const { children, pageContext } = props
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let widgets,
    navmenus,
    headerWidget,
    footerWidget,
    postLinks = [],
    latestPostLinks = []

  if (children?.props?.pageContext?.navmenus) {
    navmenus = children.props.pageContext?.navmenus
  }
  if (children?.props?.pageContext?.widgets) {
    widgets = children.props.pageContext?.widgets
    headerWidget = widgets.find(obj => obj.node.name === "Header")
    footerWidget = widgets.find(obj => obj.node.name === "Footer")
  }
  if (children?.props?.pageContext?.postLinks) {
    postLinks = children.props.pageContext.postLinks
  }
  if (children?.props?.pageContext?.latestPostLinks) {
    latestPostLinks = children.props.pageContext.latestPostLinks
  }

  console.log("Layout->props", props)
  console.log("Layout->useStaticQuery->data", data)
  console.log("Layout->latestPostLinks", latestPostLinks)

  return (
    <div className="min-h-screen">
      <Notification />
      {headerWidget && <TopNavbar navmenus={navmenus} widget={headerWidget} postLinks={postLinks} />}
      <div className="main">{children}</div>
      {footerWidget && <Footer widget={footerWidget} latestPostLinks={latestPostLinks} />}
      {isScrolled && <ScrollToTop />}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
