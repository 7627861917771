const upperCaseFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const formatText = string => {
  return string.split("-").join(" ")
}

const searchFilter = (searchValue, list, searchBy = 'title') => {
  let lowerCaseQuery = searchValue.toLowerCase();
  let filteredList = searchValue
    ? list.filter(x => x[searchBy].toLowerCase().includes(lowerCaseQuery))
    : list;
  return filteredList;
};

const categoryFilter = (post, list) => {
  console.log(`categoryFilter`, post, list)
  let postCategory = post.category ? post.category.name : null
  if (!postCategory) {
    return []
  }

  let filteredList = list.filter(obj => {
    if (post.slug === obj.slug) {
      return false
    }
    return obj.category === postCategory
  });

  return filteredList;
};


const urlHelper = href => {
  const regex = /^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
  const match = href.match(regex)
  return (
    match && {
      href: href,
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7],
    }
  )
}

module.exports = { upperCaseFirstLetter, formatText, urlHelper, searchFilter, categoryFilter }
